function frontMetricsChart(metrics) {
	var metricsChart = echarts.init(document.getElementById('metrics-chart'), null, {
		renderer: 'svg',
	});
	
	var days = metrics.date;

	/** @type EChartsOption */
	var option = {
		aria: {
			enabled: true
		},

		tooltip: {
			formatter: function (params) {
				let items = '';
			
				params.forEach (item => {
					let itemMetric = 
						'<div class="d-flex flex-column text-center px-1">' + 
							'<span>' +
									item.seriesName +
							'</span>' +
							'<span class="fw-bold" style="color:' + item.color + '">' + 
									item.data +
							'</span>' +
						'</div>'
					items += itemMetric;
				});

			return '<div class="d-flex flex-md-row flex-column echart-tooltip text-11 text-secondary rounded-2 p-1 p-lg-2">' + 
					'<div class="d-flex flex-row flex-wrap">' +
						items +
					'</div>' +
				'</div>';
			},
			trigger: 'axis',
			position: function (pos, params, el, elRect, size) {
				const viewSize = size.viewSize[0];

				// Calcula a largura total do tooltip com base na sua posição e largura
				const tooltipWidth = pos[0] + size.contentSize[0];

				// Se a largura total do tooltip for maior que a largura máxima, ajusta a posição
				if (tooltipWidth > viewSize) {
					pos[0] = viewSize - size.contentSize[0];
				} else {
					pos[0] = pos[0] - 100;
				}

				const obj = {
					top: 0,
					left: pos[0]
				};

				return obj;
			},
			textStyle: {
				fontFamily: 'Nunito',
			},
			backgroundColor: false,
			borderColor: false,
			borderWidth: 0,
			padding: 0,
			axisPointer: {
				type: "cross",
				crossStyle: {
					color: "#666",
					width: 0.6,
				},
				label: {
					color: "#FFF",
					fontSize: 10,
					fontFamily: "Nunito",
					backgroundColor: "rgba(80,80,80,0.5)",
				}
			},
		},
	
		axisPointer: {
			link: [{
				xAxisIndex: 'all'
			}],
			lineStyle: {
				color: '#666',
				width: 0.6,
			},
		},
	
		visualMap: [
			{
				type: 'piecewise',
				top: '60%',
				left: 'center',
				itemSymbol: 'circle',
				itemWidth: 8,
				itemGap: 3,
				orient: 'horizontal',
				textStyle: {
					fontSize: 11,
					fontFamily: 'Nunito',
					color: '#7E8287',
					height: 10,
				},
				pieces: [
					{
						lte: -30,
						color: '#ef4444',
						label: 'Risco',
					},
					{
						gt: -30,
						lte: -10,
						color: '#10b981',
						label: 'Ótimo',
					},
					{
						gt: -10,
						lte: 5,
						color: '#929699',
						label: 'Neutro',
					},
					{
						gt: 5,
						lte: 25,
						color: '#3b82f6',
						label: 'Descansado',
					},
					{
						gt: 25,
						color: '#f59e0b',
						label: 'Transição'
					}
				],
				seriesIndex: [4, 5],
			},
		],

		grid: [
			{
				top: '12%',
				height: '33%',
				right: 50,
				left: 50,
			},
			{
				top: '48%', 
				height: '14%',
				right: 50,
				left: 50,
			},
			{
				top: '67%', 
				height: '23%',
				right: 50,
				left: 50,
			}
		],

		xAxis: [
			{
				show: false,
				type: 'category',
				boundaryGap: 0,
				data: days,
			},{
				show: false,
				gridIndex: 1,
				type: 'category',
				boundaryGap: 0,
				data: days,
			},{
				show: false,
				gridIndex: 2,
				type: 'category',
				boundaryGap: false,
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
				},
				data: days,
			}
		],

		yAxis: [
			{
				name: 'Carga Crônica e Aguda',
				nameLocation: 'middle',
				type: 'value',
				nameGap: 35,
				boundaryGap: true,
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
					color: '#7E8287',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				maxInterval: 20,
			},
			{
				name: 'Prontidão',
				gridIndex: 1,
				nameLocation: 'middle',
				type: 'value',
				nameGap: 35,
				boundaryGap: true,
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
					color: '#7E8287',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				maxInterval: 10,
				interval: 20,
			},
			{
				name: 'Carga Diária e Strain',
				gridIndex: 2,
				nameLocation: 'middle',
				type: 'value',
				nameGap: 35,
				boundaryGap: true,
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
					color: '#7E8287',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
				maxInterval: 100,
			},
			{
				name: 'Monotonia',
				gridIndex: 2,
				nameLocation: 'middle',
				type: 'value',
				nameGap: 30,
				boundaryGap: true,
				nameTextStyle: {
					fontSize: 12,
					fontFamily: 'Nunito',
					color: '#7E8287',
				},
				axisLine: {
					lineStyle: {
						color: '#7E8287',
					},
				},
				axisLabel: {
					fontSize: 11,
					fontFamily: 'Nunito',
					margin: 10,
				},
				splitLine: {
					show: false
				},
			},
		],

		series: [
			{
				name: 'Fitness (42)',
				type: 'line',
				smooth: 0.1,
				symbol: 'none',
				itemStyle: {
					color: '#777',
				},
				lineStyle: {
					width: 1,
					color: '#777',
				},
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: 'rgba(110,110,110,0.9)'
						},
						{
							offset: 1,
							color: 'rgba(110,110,110,0.3)'
						}
					]),
				},
				data: metrics.chronicLoadLong,
			},
			{
				name: 'Fitness (28)',
				type: 'line',
				smooth: 0.1,
				symbol: 'none',
				itemStyle: {
					color: '#555',
				},
				lineStyle: {
					width: 0.5,
					color: "#555",
				},
				data: metrics.chronicLoadShort,
			},
			{
				name: 'Fadiga (7)',
				type: 'line',
				itemStyle: {
					color: '#FF00FF',
				},
				lineStyle: {
					width: 2,
					color: '#FF00FF',
				},
				symbol: 'none',
				smooth: 0.1,
				data: metrics.acuteLoadLong,
			},
			{
				name: 'Fadiga (5)',
				type: 'line',
				itemStyle: {
					color: '#FF00FF',
				},
				lineStyle: {
					width: 0.5,
					color: '#FF00FF',
				},
				symbol: 'none',
				smooth: 0.1,
				data: metrics.acuteLoadShort,
			},
			{
				name: 'Prontidão (L)',
				type: 'bar',
				barWidth: 10,
				itemStyle: {
					opacity: 0.7,
				},
				xAxisIndex: 1,
				yAxisIndex: 1,
				data: metrics.stressBalanceLong,
				zlevel: 3,
			},
			{
				name: 'Prontidão (S)',
				type: 'line',
				smooth: 0.1,
				areaStyle: {
					opacity: 0.15,
				},
				symbol: 'none',
				lineStyle: {
					width: 1,
				},
				xAxisIndex: 1,
				yAxisIndex: 1,
				data: metrics.stressBalanceShort,
				zlevel: 2,
			},
			{
				name: 'Carga Total Dia',
				type: 'bar',
				itemStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: '#ffcb77'
						},
						{
							offset: 1,
							color: '#ffe2b3'
						}
					]),
				},
				barWidth: 10,
				xAxisIndex: 2,
				yAxisIndex: 2,
				z: 2,
				data: metrics.totalTrainingLoad,
			},
			{
				name: 'Strain',
				type: 'line',
				smooth: 0.1,
				symbol: 'none',
				itemStyle: {
					color: '#4be1c3',
				},
				lineStyle: {
					width: 1,
					color: '#4be1c3',
				},
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: '#4be1c3dd'
						},
						{
							offset: 1,
							color: '#dbffffdd'
						}
					]),
				},
				xAxisIndex: 2,
				yAxisIndex: 2,
				z: 1,
				data: metrics.trainingStrain,
			},
			{
				name: 'Monotonia',
				type: 'line',
				smooth: 0.1,
				itemStyle: {
					color: '#FE6D73',
				},
				lineStyle: {
					color: '#FE6D73',
				},
				symbol: 'none',
				xAxisIndex: 2,
				yAxisIndex: 3,
				z: 3,
				data: metrics.trainingMonotony,
			}
		]
	};

	metricsChart.setOption(option);

	window.addEventListener("resize", metricsChart.resize);

	const zoomSize = 36;
	metricsChart.on('click', function (params) {
		//console.log(days[Math.max(params.dataIndex - zoomSize / 2, 0)]);
		metricsChart.dispatchAction({
			type: 'dataZoom',
			startValue: days[Math.max(params.dataIndex - zoomSize / 2, 0)],
			endValue: days[Math.min(params.dataIndex + zoomSize / 2, metrics.totalTrainingLoad.length - 1)]
		});
	});
};

frontMetricsChart(metrics);
